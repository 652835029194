<template>
  <n-grid cols="1 s:4" responsive="screen" style="padding-top: 30px">
    <n-gi span="2" offset="0 s:1">
      <n-card
        :title="
          t(`commons.actions.${model.id ? 'update' : 'create'}`, {
            reference: t('commons.labels.category', 1),
          })
        "
      >
        <n-form :model="model" ref="formRef" :rules="rules">
          <n-form-item
            :label="t('categories.createUpdate.form.parent.label')"
            path="parent"
          >
            <n-select
              v-model:value="model.parentID"
              filterable
              :placeholder="t('categories.createUpdate.form.parent.placeholder')"
              :options="parentOptions"
              :loading="loadingParentList"
              clearable
              remote
              @search="handleSearchParentOptions"
            />
          </n-form-item>
          <n-form-item :label="t('categories.createUpdate.form.name.label')" path="name">
            <n-input
              v-model:value="model.name"
              :placeholder="t('categories.createUpdate.form.name.placeholder')"
            />
          </n-form-item>
          <n-form-item
            :label="t('categories.createUpdate.form.showInHome.label')"
            path="showInHome"
          >
            <n-switch v-model:value="model.showInHome" />
          </n-form-item>
          <span v-if="childs.length === 0">
            <n-divider title-placement="left">
              {{ t("categories.createUpdate.form.referentialAmounts.title") }}
            </n-divider>
            <n-space justify="center">
              <n-form-item
                :label="
                  t('categories.createUpdate.form.referentialAmounts.pricePerDay.label')
                "
                path="pricePerDayReference"
              >
                <n-input-number
                  :show-button="false"
                  :placeholder="
                    t(
                      'categories.createUpdate.form.referentialAmounts.pricePerDay.placeholder'
                    )
                  "
                  :style="{ width: '100%' }"
                  v-model:value="model.pricePerDayReference"
                >
                  <template #prefix>{{ t("commons.financial.currencySymbol") }}</template>
                </n-input-number>
              </n-form-item>
              <n-form-item
                :label="
                  t('categories.createUpdate.form.referentialAmounts.deposit.label')
                "
                path="depositReference"
              >
                <n-input-number
                  :show-button="false"
                  :placeholder="
                    t(
                      'categories.createUpdate.form.referentialAmounts.deposit.placeholder'
                    )
                  "
                  :style="{ width: '100%' }"
                  v-model:value="model.depositReference"
                >
                  <template #prefix>{{ t("commons.financial.currencySymbol") }}</template>
                </n-input-number>
              </n-form-item>
            </n-space>
            <n-divider title-placement="left">
              {{ t("categories.createUpdate.form.baseChecklist.title") }}
            </n-divider>
            <n-space justify="center" class="pb-4">
              <n-dynamic-input
                :create-button-props="{ text: t('commons.actions.add') }"
                v-model:value="model.checkListBaseFields"
                :on-create="onNewCheckList"
                #="{ index, value }"
              >
                <n-form-item
                  :label="t('categories.createUpdate.form.baseChecklist.type.label')"
                  ignore-path-change
                  :path="`checkListBaseFields[${index}].name`"
                >
                  <n-select
                    style="min-width: 15em"
                    :placeholder="
                      t('categories.createUpdate.form.baseChecklist.type.placeholder')
                    "
                    :options="options"
                    v-model:value="model.checkListBaseFields[index].type"
                  />
                </n-form-item>
                <n-form-item
                  :label="t('categories.createUpdate.form.baseChecklist.name.label')"
                  ignore-path-change
                  :path="`checkListBaseFields[${index}].name`"
                  :rule="dynamicRule"
                >
                  <n-input
                    style="min-width: 15em"
                    :placeholder="
                      t('categories.createUpdate.form.baseChecklist.name.placeholder')
                    "
                    @keydown.enter.prevent
                    v-model:value="model.checkListBaseFields[index].name"
                  />
                </n-form-item>
              </n-dynamic-input>
            </n-space>
          </span>
          <div v-else class="mb-4">
            <n-divider title-placement="left">
              {{ t("categories.createUpdate.form.baseChecklist.title") }}
            </n-divider>
            <Table
              :data="childs"
              :fieldsToHide="[
                'id',
                'pricePerDayReference',
                'depositReference',
                'checkListBaseFields',
                'parent',
                'parentID',
                'createdAt',
                'updatedAt',
                '_version',
                '_deleted',
                '_lastChangedAt',
              ]"
              :itemsActions="childItemActions"
            />
          </div>

          <n-space justify="center">
            <n-button @click="cancelClick">
              {{ t("commons.actions.cancel") }}
            </n-button>
            <n-button @click="handleValidateClick">
              {{ model.id ? t("commons.actions.update") : t("commons.actions.save") }}
            </n-button>
          </n-space>
        </n-form>
      </n-card>
    </n-gi>
  </n-grid>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import {
  useMessage,
  NGrid,
  NGi,
  NCard,
  NForm,
  NFormItem,
  NSelect,
  NInput,
  NDivider,
  NSpace,
  NInputNumber,
  NDynamicInput,
  NButton,
  NSwitch,
} from "naive-ui";
import { useStore } from "vuex";
import Table from "@/components/Table.vue";
import { PencilOutline as EditIcon } from "@vicons/ionicons5";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

export default {
  props: {
    categoryId: String,
  },
  components: {
    Table,
    NGrid,
    NGi,
    NCard,
    NForm,
    NFormItem,
    NSelect,
    NInput,
    NDivider,
    NSpace,
    NInputNumber,
    NDynamicInput,
    NButton,
    NSwitch,
  },
  setup(props) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const formRef = ref(null);
    const modelRef = ref({
      parentID: null,
      name: null,
      checkListBaseFields: null,
      pricePerDayReference: null,
      depositReference: null,
    });
    const message = useMessage();
    const loadingParentListRef = ref(false);
    const parentOptionsRef = ref([]);
    const filteredParentOptionsRef = ref([]);
    const childsRef = ref([]);
    let reload = false;

    const update = (category) =>
      store.dispatch("maintainer_category/updateCategory", category);

    const create = (category) =>
      store.dispatch("maintainer_category/createCategory", category);

    const loadAvailableParents = () => {
      store
        .dispatch("maintainer_category/getAvailableParents", {
          id: modelRef.value.id,
          childsIds: childsRef.value.map((child) => child.id),
        })
        .then((parentOptions) => {
          parentOptionsRef.value = parentOptions;
          filteredParentOptionsRef.value = parentOptions.map((parent) => {
            return { label: parent.name, value: parent.id };
          });
        });
    };

    const loadData = (categoryId) => {
      store
        .dispatch("maintainer_category/getCategoryById", categoryId)
        .then((selectedCategory) => {
          modelRef.value = (({
            id,
            parentID,
            name,
            checkListBaseFields,
            pricePerDayReference,
            depositReference,
            _version,
          }) => ({
            id,
            parentID,
            name,
            checkListBaseFields,
            pricePerDayReference,
            depositReference,
            _version,
          }))(selectedCategory);

          store
            .dispatch("maintainer_category/getChildsByCategoryId", modelRef.value.id)
            .then((childs) => {
              childsRef.value = childs.map((category) => {
                return {
                  ...category,
                  showInHome: category.showInHome
                    ? t("commons.confirmation.yes")
                    : t("commons.confirmation.no"),
                };
              });
              loadAvailableParents();
            });
        });
    };

    onMounted(() => {
      if (props.categoryId) {
        loadData(props.categoryId);
      } else {
        loadAvailableParents();
      }
    });
    watch(
      () => route.params.categoryId,
      async (newId) => {
        if (newId && reload) {
          loadData(newId);
        }
      }
    );

    return {
      props,
      t,
      formRef,
      model: modelRef,
      rules: {
        name: {
          required: true,
          message: t("commons.form.validation.required"),
          trigger: ["input", "blur"],
        },
      },
      dynamicRule: {
        required: true,
        trigger: ["blur", "change"],
        message: t("commons.form.validation.required"),
      },
      options: computed(() => store.getters["shared/options_inputType"]),
      handleValidateClick(e) {
        e.preventDefault();
        formRef.value.validate((errors) => {
          if (!errors) {
            if (modelRef.value.checkListBaseFields?.length > 0) {
              for (
                let index = 0;
                index < modelRef.value.checkListBaseFields.length;
                index++
              ) {
                modelRef.value.checkListBaseFields[index].id = index + 1;
              }
            }
            if (modelRef.value.id) {
              update(modelRef.value)
                .then((categoryUpdated) => {
                  modelRef.value._version = categoryUpdated._version;
                })
                .finally(() => {
                  t("commons.actions.saved", {
                    reference: t("commons.labels.category", 1),
                  });
                })
                .catch((err) => {
                  message.error(err);
                });
            } else {
              create(modelRef.value)
                .then((categoryCreated) => {
                  modelRef.value = (({
                    id,
                    parentID,
                    name,
                    checkListBaseFields,
                    pricePerDayReference,
                    depositReference,
                    _version,
                  }) => ({
                    id,
                    parentID,
                    name,
                    checkListBaseFields,
                    pricePerDayReference,
                    depositReference,
                    _version,
                  }))(categoryCreated);
                })
                .finally(() => {
                  t("commons.actions.saved", {
                    reference: t("commons.labels.category", 1),
                  });
                  router.push({
                    name: "update-category",
                    params: { categoryId: modelRef.value.id },
                  });
                })
                .catch((err) => {
                  message.error(err);
                });
            }
          }
        });
      },
      cancelClick(e) {
        e.preventDefault();
        router.push({
          name: "categories",
        });
      },
      onNewCheckList() {
        return {
          id: 0,
          name: "",
          type: "STRING",
        };
      },
      childs: childsRef,
      loadingParentList: loadingParentListRef,
      parentOptions: filteredParentOptionsRef,
      handleSearchParentOptions(query) {
        if (query && query.length > 0) {
          filteredParentOptionsRef.value = parentOptionsRef.value
            .filter((item) => item.name.toLowerCase().includes(query.toLowerCase()))
            .map((parent) => {
              return { label: parent.name, value: parent.id };
            });
        } else {
          filteredParentOptionsRef.value = parentOptionsRef.value.map((parent) => {
            return { label: parent.name, value: parent.id };
          });
        }
      },
      childItemActions: (item) => {
        let actions = [];
        actions.push({
          label: t("commons.actions.update"),
          key: "update",
          icon: EditIcon,
          props: {
            onClick: () => {
              reload = true;
              router.push({
                name: "update-category",
                params: { categoryId: item.id },
              });
            },
          },
        });
        return actions;
      },
    };
  },
};
</script>

<style lang="scss" scoped>
:deep(.n-dynamic-input-item__action) {
  padding-top: 25px;
}
</style>
